import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { Button,  Box, CircularProgress, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Customer = ( ) => { 
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try { 
      const apiUrl = process.env.REACT_APP_API_URL;
      
      const response = await axios.get(`${apiUrl}/customer`); 
      
      setCustomerData(response.data);
    } catch (error) {
      console.error("Error fetching customer:", error);
    } finally {
      setLoading(false);
    }
     
  };

  useEffect(() => {
    fetchData();
  }, []);


  
  
  
  // Column settings for MUIDataTable
  const columns = [
    { name: 'username', label: 'Username' },
    { name: 'mobile', label: 'Phone' },
    { name: 'email', label: 'Email' },
    { name: 'created_At', label: 'Date' },
    { name: 'status', label: 'Status' },
     
  ];

  // Options for the table
  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    pagination: true,
    rowsPerPage: 10,
    responsive: 'standard', // Or 'scrollMaxHeight'
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '12px', // Set default cell font size
          } 
        },
      } 
    },
  });
  

    return ( 
      <div>
       
    <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}>  
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={fetchData} size="small">
        Fetch
        </Button>   
      </div>
       {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CircularProgress />
        </div>
      ) : (
 
        
         <ThemeProvider theme={theme}> 
          <MUIDataTable
            title={"Customers"}
            data={customerData}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
 
      )}
        </Box>
    
    </div>
      
      );
};
 

export default Customer;
