import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography,
  TextField, MenuItem
 } from '@mui/material';
import axios from 'axios';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//orderId, handleClose
const OrderDetail = ({ open, onClose, initialValues }) => {
  const [orderData, setOrderData] = useState([]);
  const [orderMaster, setOrderMaster] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataloading, setDataLoading] = useState(false);
  const apiUrlFile = process.env.REACT_APP_API_URL_FILE;
  const [paymentStatus, setPaymentStatus] = useState('');
  const [fulfillmentStatus, setFulfillmentStatus] = useState('');
  //const [isOrderSuccess, setIsOrderSuccess] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); // State to track error message

  const fetchOrderData = async () => {
    setDataLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/order/${initialValues.orderId}`);
      console.log(response.data);
      setOrderData(response.data);
      setOrderMaster(response.data);
      if (response.data.length > 0){ 
        console.log(response.data[0].paymentStatus); 
        setPaymentStatus(response.data[0].paymentStatus); // Set payment status
        setFulfillmentStatus(response.data[0].fulfillmentStatus); // Set payment status
      }
      //console.log(response.data.paymentStatus);
    } catch (error) {
      console.error("Error fetching order detail:", error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (initialValues && open) {
      
      //setIsOrderSuccess(false);
      fetchOrderData();
    }
  }, [initialValues, open]);

  // Calculate total price
  const totalAmount = orderData.length > 0 
    ? orderData[0].orderItems.reduce((sum, item) => sum + item.subtotal, 0) 
    : 0;

 /// const flattenedData = orderData.length;
 // console.log(flattenedData);
 // Flatten cart items to display in the table
   const flattenedData = orderData.length > 0
 ? orderData[0].orderItems.map(item => ({
     productCode: item.product.product_Code,
     productTitle: item.product.title,
     quantity: item.quantity,
     subtotal: item.subtotal,
     productVariant: item.productVariant
   }))
 : [];   

 // Column settings for MUIDataTable
   const columns = [ 
  { name: "productCode", label: "Code" },
  { name: "productTitle", label: "Product" },
  { name: "productVariant", label: "Variant" }, 
  { name: "quantity", label: "Quantity" },
  { name: "subtotal", label: "Subtotal" },
];  

// Options for the table
 const options = {
  selectableRows: 'none',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  pagination: false,
  responsive: 'standard', // Or 'scrollMaxHeight'
};

const handleSave = async () => {
  setLoading(true); // Start loading
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const payload = {
      fulfillmentStatus: fulfillmentStatus,
      paymentStatus: paymentStatus,
    };
    // Convert the form data object to a JSON string
    const jsonData = JSON.stringify(payload);

    // Log the JSON string to the console
    console.log("Post data:", jsonData);
    
     const response =  await axios.put(`${apiUrl}/order/status/${initialValues.orderId}`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });  // Show success message
    // Fetch the response data
     const responseData = response.data;
     console.log('response:', responseData);
     if (responseData.status) {
  
      console.log('response:', responseData);
      
      //setIsOrderSuccess(true); 
      setErrorMessage(''); 
      onClose(true);
      
    } else {
      //setIsOrderSuccess(false); 
      setErrorMessage('Failed to update order status:', responseData.status, responseData.reason);
      console.error('Signup failed:', responseData.status, responseData.reason);
    } 
    
  } catch (error) {
      //setIsOrderSuccess(false); // Set success state to false
      setErrorMessage('Failed to update order status:', error); // Set an error message
      console.error('Error during update order status:', error);
  } finally {
    setLoading(false); // End loading
  }
};

     /*   <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth> */
 return (
      <Dialog open={open} onClose={() => onClose(false)} maxWidth="lg" fullWidth> 
      <DialogTitle>Order Details</DialogTitle>
      <DialogContent>
     {/* Conditionally render success or error message */}
        {/* {isOrderSuccess && (
          <div className="success-message" style={{ color: 'green' }}>
            Your order status has been successfully submitted!
          </div>
        )} */}
        {errorMessage && (
          <div className="error-message" style={{ color: 'red' }}>
            {errorMessage}
          </div>
        )}
        {dataloading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : orderMaster ? (
        <> 
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            {/* Left side: Order Code, Order Date, Order Status */}
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h5" gutterBottom>
                {orderMaster[0].orderCode}
              </Typography>
              <Typography variant="body1">
                <strong>Order Date:</strong> {orderMaster[0].createdAt}
              </Typography>
              <Typography variant="body1">
                <strong>Payment attached:</strong>
                <a
                  href={`${apiUrlFile}/file/display?folderName=estore&fileName=${orderMaster[0].filename}`} // This is the file URL or path
                  target="_blank" // Opens in a new tab
                  rel="noopener noreferrer" // Security enhancement
                  style={{ color: '#3f51b5', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {orderMaster[0].filename} {/* Display the filename */}
                </a>
              </Typography>
              <div> {/* Use a <div> instead of <p> */}
                <TextField
                  fullWidth
                  margin="normal"
                  select
                  label="Payment Status"
                  name="paymentStatus"
                  value={paymentStatus} // Bind the state value here
                  onChange={(e) => setPaymentStatus(e.target.value)} // Update state on change
                >
                  <MenuItem value="Paid">Paid</MenuItem>
                  <MenuItem value="Unpaid">Unpaid</MenuItem>
                </TextField>
              </div>
              <div> {/* Use a <div> instead of <p> */}
                <TextField
                  fullWidth
                  margin="normal"
                  select
                  label="Fulfillment Status"
                  name="fulfillmentStatus"
                  value={fulfillmentStatus} // Bind the state value here
                  onChange={(e) => setFulfillmentStatus(e.target.value)} // Update state on change
                >
                  <MenuItem value="Fulfilled">Fulfilled</MenuItem>
                  <MenuItem value="Unfulfilled">Unfulfilled</MenuItem>
                </TextField>
              </div>
              <Typography variant="body1">
                <AccountCircleIcon /> {orderMaster[0].shipName}
              </Typography>
              <Typography variant="body1">
                <PhonelinkRingIcon /> {orderMaster[0].shipPhone}
              </Typography>
              <Typography variant="body1">
                <PinDropIcon />
              </Typography>
              <Typography variant="body1">{orderMaster[0].shipAddress}</Typography>
              <Typography variant="body1">{orderMaster[0].shipCity}</Typography>
              <Typography variant="body1">{orderMaster[0].shipPostcode}</Typography>
              <Typography variant="body1">{orderMaster[0].shipState}</Typography>
            </Box>

         
          </Box>


         <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 3 }}> 
             <MUIDataTable
              title={"Product"}
              data={flattenedData}
              columns={columns}
              options={options}
            /> 
          </Box> 
        {/* Total and Submit Order Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 1 }}>
          <Typography variant="h6" sx={{ marginRight: 2, color: 'red' }}>
            Total: RM{totalAmount}
          </Typography>
    
        </Box>
          </>

          
        ) : (
          <Typography variant="body1">No order details available.</Typography>
        )}

 
      </DialogContent>
      <DialogActions>
        <Button  onClick={() => {
                        onClose(false); 
                    }}
                     color="primary" size="small">Close</Button>
                     
                <Button  color="secondary" onClick={handleSave}  variant="contained" size="small" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>

            {/* <Button variant="contained" onClick={handleSave} color="secondary" size="small">
              Save
            </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default OrderDetail;
