import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Link, Box } from '@mui/material';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import PaymentUploadForm from './PaymentUploadForm';
import PaymentForm from './PaymentForm';


const PaymentMethod = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openNewForm, setOpenNewForm] = useState(false); // Control SaveForm open state
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row
  const [openEditForm, setOpenEditForm] = useState(false); // Control EditForm open state
  const [selectedUploadRow, setSelectedUploadRow] = useState(null); // Track selected row
  const [openUploadForm, setOpenUploadForm] = useState(false); // Control EditForm open state

 
  const fetchData = async () => {
    setLoading(true);
    try { 
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/paymentoption`); 
      
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleNewClick = () => {
    setSelectedRow(null); // Clear selection when creating a new save
    setOpenNewForm(true); // Open SaveForm
  };

  const handleEditClick = (rowData) => {
    
    setSelectedRow(rowData);
    
    setOpenEditForm(true); // Open EditForm
  };

  const handleUploadClick = (rowData) => {
    setSelectedUploadRow(rowData);
    setOpenUploadForm(true); // Open EditForm
  };

  const handleNewFormClose = (shouldReload) => {
    setOpenNewForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

  const handleEditFormClose = (shouldReload) => {
    setOpenEditForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

  const handleUploadFormClose = (shouldReload) => {
    setOpenUploadForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

  const handleDeleteClick = async (rowData) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this data?");
    if (confirmDelete) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
       
        console.log(rowData.id);
        await axios.delete(`${apiUrl}/paymentoption/${rowData.id}`);
        setData(data.filter(item => item.id !== rowData.id)); // Remove deleted item from state
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    }
  };

   // Flatten cart items to display in the table
   const flattenedData = data.map(item => ({
    paymentOptionId: item.paymentOptionId,
    paymentTypeId: item.paymentType?.paymentTypeId,
    paymentBankId: item.paymentBank?.paymentBankId,
    paymentTypeTitle: item.paymentType?.title || '',
    paymentBankTitle: item.paymentBank?.title || '',
    accountName: item.accountName,
    accountNo: item.accountNo,
    status: item.status,
    qrCode: item.qrCode, 
}));


  const columns = [
    { name: "paymentOptionId", label: "ID", options: { display: false } }, // Hidden ID column used for PaymentForm
    { name: "paymentTypeId", label: "Type", options: { display: false } }, // Hidden ID column used for PaymentForm
    { name: "paymentBankId", label: "Bank", options: { display: false } }, // Hidden ID column used for PaymentForm
    { name: "qrCode", label: "QRCode", options: { display: false } }, // Hidden ID column used for PaymentForm
    { name: "paymentTypeTitle", label: "Type"  },
    { name: "paymentBankTitle", label: "Bank"},
    { name: "accountName", label: "Acct. Name"},
    { name: "accountNo", label: "Acct. No"} ,
    { name: "status", label: "Status"} ,
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div>
            <Link
              component="button"
              variant="body2"
              onClick={() => handleEditClick(data[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '0.875rem', marginRight: '10px' }}
            >
            Edit
            </Link>
            <br />
            <Link
              component="button"
              variant="body2"
              onClick={() => handleUploadClick(data[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '0.875rem', marginRight: '10px' }}
            >
            Upload
            </Link>
            <br />
            <Link
              component="button"
              variant="body2"
              onClick={() => handleDeleteClick(data[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#f44336', cursor: 'pointer', fontSize: '0.875rem' }}
            >
            Delete
            </Link> 
        </div>
          
        )
      }
    }
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    pagination: true,
    rowsPerPage: 10,
    responsive: 'standard', // Or 'scrollMaxHeight'
  };

  return (
    <div>
    <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}>  
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={fetchData} size="small">
        Fetch
        </Button>
        <Button variant="contained" color="secondary" onClick={handleNewClick} style={{ marginLeft: 10 }} size="small">
          New Payment Method
        </Button>      
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CircularProgress />
        </div>
      ) : (
       //  <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
        
        
          <MUIDataTable
            title={"Payment Method List"}
            data={flattenedData}
            columns={columns}
            options={options}
          />
      )}
        </Box>
        <PaymentForm  open={openNewForm} onClose={handleNewFormClose}  /> 
        {selectedRow && (
          <PaymentForm open={openEditForm} onClose={handleEditFormClose} initialValues={selectedRow} />
        )} 
         {selectedUploadRow && (
          <PaymentUploadForm open={openUploadForm} onClose={handleUploadFormClose} initialValues={selectedUploadRow} />
        )} 
    </div>
  );
};

export default PaymentMethod;
