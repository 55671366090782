import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import {  Button, Box, CircularProgress, Link } from '@mui/material';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProductVariant from './ProductVariant';
import ProductForm from './ProductForm';

const Product = ( ) => { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openNewForm, setOpenNewForm] = useState(false); // Control SaveForm open state
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row
  const [openEditForm, setOpenEditForm] = useState(false); // Control EditForm open state
  const [selectedVariantRow, setSelectedVariantRow] = useState(null); // Track selected row
  const [openVariantForm, setOpenVariantForm] = useState(false); // Control EditForm open state

  const fetchData = async () => {
    setLoading(true);
    try { 
      const apiUrl = process.env.REACT_APP_API_URL;
      
      const response = await axios.get(`${apiUrl}/product/setup`); 
      
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
     
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNewClick = () => {
    setSelectedRow(null); // Clear selection when creating a new save
    setOpenNewForm(true); // Open SaveForm
  };

  const handleEditClick = (rowData) => {
    
    setSelectedRow(rowData);
    
    setOpenEditForm(true); // Open EditForm
  };

  const handleVariantClick = (rowData) => {
    setSelectedVariantRow(rowData);
    setOpenVariantForm(true); // Open EditForm
  };

  const handleNewFormClose = (shouldReload) => {
    setOpenNewForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

  const handleEditFormClose = (shouldReload) => {
    setOpenEditForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

  const handleVariantFormClose = (shouldReload) => {
    setOpenVariantForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

   
  
  
  
  // Column settings for MUIDataTable
  const columns = [
    { name: 'product_id', label: 'Product Id', options: { display: false } },
    { name: 'product_category_id', label: 'Category Id', options: { display: false } },
    { name: 'categoryTitle', label: 'Category' },
    { name: 'product_Code', label: 'Code' },
    { name: 'title', label: 'Title' },
    { name: 'description', label: 'Desc' , options: { display: false }},
    { name: 'stock_Quantity', label: 'Stock' },
    { name: 'price', label: 'Price' },
    { name: 'image', label: 'Image', options: { display: false } },
    { name: 'brand', label: 'Brand', options: { display: false } },
    { name: 'status', label: 'Status' },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div>
            <Link
              component="button"
              variant="body2"
              onClick={() => handleEditClick(data[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '0.875rem', marginRight: '10px' }}
            >
            Edit
            </Link>
            <br />
            <Link
              component="button"
              variant="body2"
              onClick={() => handleVariantClick(data[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '0.875rem', marginRight: '10px' }}
            >
            Variant
            </Link>
             
        </div>
          
        )
      }
    }
  ];

  // Options for the table
  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    pagination: true,
    rowsPerPage: 10,
    responsive: 'standard', // Or 'scrollMaxHeight'
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '12px', // Set default cell font size
          } 
        },
      } 
    },
  });
  

    return ( 
      <div>
       
       <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}> {/* Add padding to the bottom */}
       <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={fetchData} size="small">
        Fetch
        </Button>
        <Button variant="contained" color="secondary" onClick={handleNewClick} style={{ marginLeft: 10 }} size="small">
          New Product
        </Button>      
      </div>
       {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CircularProgress />
        </div>
      ) : (
 
         <ThemeProvider theme={theme}> 
          <MUIDataTable
            title={"Products"}
            data={data}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
      )}
    
    </Box>  
        <ProductForm  open={openNewForm} onClose={handleNewFormClose}  /> 
        {selectedRow && (
          <ProductForm open={openEditForm} onClose={handleEditFormClose} initialValues={selectedRow} />
        )} 
         {selectedVariantRow && (
          <ProductVariant open={openVariantForm} onClose={handleVariantFormClose} initialValues={selectedVariantRow} />
        )} 
    </div>
      
      );
};
 

export default Product;
