import React from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleIcon from '@mui/icons-material/People';

const Dashboard = () => {
  const steps = [
    { title: 'Total Orders to Fulfill', link: '/orders', icon: <ShoppingCartIcon fontSize="large" />, count: 120 },
    { title: 'Total Unpaid Orders', link: '/unpaid-orders', icon: <PaymentIcon fontSize="large" />, count: 45 },
    { title: 'Total Customers', link: '/customers', icon: <PeopleIcon fontSize="large" />, count: 5000 },
  ];

  return (
    <Box sx={{ marginTop: 2, marginBottom: 4 }}>
    <Grid container spacing={3}>
  {steps.map((step, index) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <Card>
        <CardActionArea component={Link} to={step.link}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" >
                {step.icon}
              </Box>
              <Typography variant="h4" color="primary">
                {step.count}
              </Typography>
            </Box>
            <Typography variant="h6">
              {step.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  ))}
</Grid>
</Box>
  );
};

export default Dashboard;
