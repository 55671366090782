import React, { useState, useEffect } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    CircularProgress, Input, Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
const FILE_SIZE = 5000 * 1024; // 5MB
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png' ];

const PaymentForm = ({ open, onClose, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOrderSuccess, setIsOrderSuccess] = useState(false); // State to track success
  const [errorMessage, setErrorMessage] = useState(''); // State to track error message
  const [downloadLink, setDownloadLink] = useState("");

  const formik = useFormik({
    initialValues: {
      file: null, // Add file field to initialValues
    },
    validationSchema: Yup.object({
        file: Yup.mixed()
        .required('A file is required')
        .test(
          'fileSize',
          'File size is too large (max 5MB)',
          (value) => value && value.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Unsupported file format (only JPG, PNG)',
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
    onSubmit: async (values) => {

        setLoading(true);
        try {

            // Step 1: Prepare FormData for file upload
            const formData = new FormData();
            formData.append("folderName", "manager");
            formData.append("file", values.file);
            
            // Step 2: Upload file
            const apiUrlFile = process.env.REACT_APP_API_URL_FILE;
            const fileResponse = await axios.post(`${apiUrlFile}/file/upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            
            // Step 3: Check file upload response status
            if (fileResponse.status === 200){

                const apiUrlFile = process.env.REACT_APP_API_URL_FILE;
                if (open && initialValues) {
                    setDownloadLink(`${apiUrlFile}/file/display?folderName=manager&fileName=`+fileResponse.data);    
                }
              // Extract file name only
              const payload = {
                ...values,
                qrcode: values.file ? fileResponse.data : null, 
              };

              const cleanPayload = JSON.parse(JSON.stringify(payload));
              
              // Log the JSON string to the console
              console.log("Payload:", cleanPayload);
              //console.log('Payload:', payload);
              const apiUrl = process.env.REACT_APP_API_URL;
              const response = await axios.put(`${apiUrl}/paymentoption/qr/${initialValues.paymentOptionId}`, payload, {
                headers: {
                  'Content-Type': 'application/json',
                },
              }); 
    
              // Step 6: Check   submission response status
              if (response.status === 200) {
                console.log('data created successfully:', response.data);
                //setIsOrderSuccess(true); // Set success state to true
                setErrorMessage(''); // Clear any previous errors
                onClose(true);
              } else {
              // console.error('Failed to create data:', response.status, response.data);
                //setIsOrderSuccess(false); 
                setErrorMessage('Failed to create data:', response.status, response.data);  
              }
              
            } else {
              //console.error('File upload failed with response:', fileUploadResponse.status, fileUploadResponse.data);
              //setIsOrderSuccess(false); // Set success state to false
              setErrorMessage('File upload failed with response:', fileResponse.status, fileResponse.data); // Set an error message
            }

        } catch (error) {
          //console.error("Error creating order:", error);
          // Enhanced error handling
          if (error.response) {
              // Server responded with a status other than 2xx
              //console.error('Error response:', error.response.status, error.response.data);
              //setIsOrderSuccess(false); // Set success state to false
              setErrorMessage(`File upload or order creation failed: ${error.response.data.message || 'Server error'}`); // Set an error message
              //alert(`File upload or order creation failed: ${error.response.data.message || 'Server error'}`);
          } else if (error.request) {
              // No response was received
              //console.error('No response from server:', error.request);
              //setIsOrderSuccess(false); // Set success state to false
              setErrorMessage('No response from server. Please try again later.'); // Set an error message
              //alert('No response from server. Please try again later.');
          } else {
              // Something else caused the error
              //console.error('Error creating order:', error.message);
              //setIsOrderSuccess(false); // Set success state to false
              setErrorMessage(`Error: ${error.message}`); // Set an error message
              //alert(`Error: ${error.message}`);
          }
        } finally {
          setLoading(false);
        }
    }
  });

  
   useEffect(() => {
    const apiUrlFile = process.env.REACT_APP_API_URL_FILE;
    if (open && initialValues) {
        setDownloadLink(`${apiUrlFile}/file/display?folderName=manager&fileName=`+initialValues.qrCode);    
    }
  }, [open, initialValues]); 

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      formik.setFieldValue('file', file);          
    } 
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <form onSubmit={formik.handleSubmit}>
      <DialogTitle>{initialValues ? 'Upload QR Code' : 'New QR Code'}</DialogTitle>
        <DialogContent>
            {/* {isOrderSuccess && (
            <div className="success-message" style={{ color: 'green' }}>
                Your data has been successfully submitted!
            </div>
            )} */}
            {errorMessage && (
            <div className="error-message" style={{ color: 'red' }}>
                {errorMessage}
            </div>
            )}

 
            <Input
              id="file"
              name="file"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            /> 
              {formik.errors.file && formik.touched.file && <div>{formik.errors.file}</div>} 
            
            {downloadLink && (
            <div>
            <Typography variant="body1">QR: <a href={downloadLink} target="_blank" rel="noopener noreferrer">{initialValues.qrCode}</a></Typography>
            </div>
            )} 
        </DialogContent>
        <DialogActions>
        <Button
            onClick={() => {
              onClose(false);
              formik.resetForm();
              setData([]); 
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PaymentForm;
