import React, { useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Paper, Box, Grid, 
  Typography, CircularProgress, Link as Linking, List, ListItem, ListItemText, Divider ,Chip, Alert, AlertTitle } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Linking color="inherit" href="https://appserverless.com" target="_blank">
        AppServerless Software Solution
      </Linking>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide({onLoginSuccess}) {
  /* const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    onLoginSuccess();
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  }; */

  // Sample release notes data
  const releaseNotes = [
    {
      version: '1.2.2',
      type: 'Enhancement',
      color: 'success',
      date: 'October 21, 2024',
      modules: ['Store Manager > Report > Export Order CSV'],
      changes: ['Update Product variants such as size, quantity and validate total amount of purchased.'],
    },
    {
      version: '1.2.1',
      type: 'New Feature',
      color: 'secondary',
      date: 'October 20, 2024',
      modules: ['Store Manager > Report > Export Order CSV'],
      changes: ['Download dataset in CSV format, including product, variants, and school name.'],
    },
    {
      version: '1.1.4',
      type: 'Enhancement',
      color: 'success',
      date: 'October 19, 2024',
      modules: ['Store Front > Product'],
      changes: ['Customer can browse catalog by team/school selection'],
    },
    {
      version: '1.1.3',
      type: 'Enhancement',
      color: 'success',
      date: 'October 18, 2024',
      modules: ['Store Front > Checkout'],
      changes: ['Customer can enter student name, choose school and enter parent contact'],
    },
    {
      version: '1.1.2',
      type: 'Enhancement',
      color: 'success',
      date: 'October 18, 2024',
      modules: ['Store Front'],
      changes: ['Login/Sign Up buttons showing on Appbar for visitor', 'Account icon with dropdown menus showing once member logged in'],
    },
    {
      version: '1.1.1',
      type: 'Bug Fix',
      color: 'error',
      date: 'October 18, 2024',
      modules: ['Store Front'],
      changes: ['WhatsApp floating button remmoval due to overlapping with checkout button'],
    },
  ];

  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: false,
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'), //.email('Invalid email format')
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true); // Start loading
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log(`${apiUrl}/user/login`);
        // Replace with your API endpoint
        const response = await fetch(`${apiUrl}/user/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: values.username,
            password: values.password,
          })
        });

        if (response.ok) {
          const data = await response.json();
          Cookies.set('token', data.token, { path: '/' });
          console.log('Login successful! Token:', data.token);
          setSuccessMessage('Log in successfully!');
          setErrorMessage('');
          onLoginSuccess(); // Update authentication state
          navigate('/master/home');
        } else {
          setErrorMessage('Log in failed :' + response.status + ' ' + response.statusText);
          setSuccessMessage('');
          console.error('Login failed:', response.status, response.statusText);
        }
      } catch (error) {
        setErrorMessage('Error during Log in:' + error);
        setSuccessMessage('');
        console.error('Error during login:', error);
      } finally {
        setLoading(false); // End loading
      }
    },
  });


  return (
    <ThemeProvider theme={defaultTheme}> 
        <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            {successMessage && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {successMessage}
            </Alert>
            )}
            {errorMessage && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                name="remember"
                checked={formik.values.remember}
                onChange={formik.handleChange}
              />
              <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading} // Disable while loading
            >
              {loading ? <CircularProgress size={24} /> : 'Sign In'} {/* Show loading spinner */}
            </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url("/static/images/templates/sign-in-side-bg.png")',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {/* Release Notes Section */}
          <Box sx={{ maxHeight: '100vh', overflowY: 'auto', p: 2 }}>
            <Typography variant="h6" gutterBottom>
              What's new on this release?
            </Typography>
            <List>
              {releaseNotes.map((note, index) => (
                <React.Fragment key={index}>
                  {/* <ListItem>
                    <ListItemText
                      primary={`Version: ${note.version}`}
                      secondary={`Date: ${note.date} - Modules: ${note.modules.join(', ')}`}
                    />
                  </ListItem>
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {note.changes.join(', ')}
                  </Typography> */}
                  <ListItem alignItems="flex-start">
                  <ListItemText
                      primary={
                        <>
                          {/* Using Chip for version text */}
                          <Chip
                            label={`${note.type}`}
                            color={`${note.color}`} // You can change this to 'secondary', 'success', 'error', etc.
                            size="small" // Optional: Adjust the size of the Chip
                            sx={{ marginRight: 1 }} // Optional: Add some spacing
                          />
                          <Chip
                            label={`Version ${note.version}`}
                            color="info" // You can change this to 'secondary', 'success', 'error', etc.
                            size="small" // Optional: Adjust the size of the Chip
                            sx={{ marginRight: 1 }} // Optional: Add some spacing
                          />
                          {note.date}
                        </>
                      }
                    secondary={
                      <>
                        <Typography component="span" variant="h6" color="textPrimary">
                          {note.modules.join(', ')}
                        </Typography>
                        <br />
                        {note.changes.join(', ')}
                      </>
                    }
                  />
                </ListItem>
                  {index < releaseNotes.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Grid>
        
      </Grid>
    </ThemeProvider>
  );
}