import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import {  Button, Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import OrderDetail from './OrderDetail';  // Import the OrderDetail component
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Order = ( ) => { 
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row
  const [openEditForm, setOpenEditForm] = useState(false); // Control EditForm open state
 // const [dialogOpen, setDialogOpen] = useState(false);

   // Example cart data with images
   /* const [orderItems] = useState([
    { code: 'J001', create_at: '12-01-2025 12:00:30', ship_at: '15-01-2025', status: 'New', total: 50 },
    { code: 'J002', create_at: '13-01-2025 13:33:30', ship_at: '18-01-2025', status: 'Proceed', total: 30 },
    { code: 'J003', create_at: '15-01-2025 14:00:55', ship_at: '20-01-2025', status: 'Shipped', total: 90 },
  ]); */

  const fetchData = async () => {
    setLoading(true);
    try { 
      const apiUrl = process.env.REACT_APP_API_URL;
      const customerId = "all";
      
      const response = await axios.get(`${apiUrl}/order/customer/${customerId}`); 
      
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    } finally {
      setLoading(false);
    }
     
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate total price
  // const total = orderData.reduce((sum, item) => sum + item.subtotal, 0);

  const apiUrlFile = process.env.REACT_APP_API_URL_FILE;
  // Column settings for MUIDataTable
  const columns = [
    { name: 'orderCode', label: 'Order Code' },
    { name: 'createdAt', label: 'Order Date' },
    { name: 'paymentStatus', label: 'Payment Status' },
    { name: 'fulfillmentStatus', label: 'Fulfillment Status' },
    { name: 'username', label: 'Customer' },
    { name: 'mobile', label: 'Contact' },
    //{ name: 'filename', label: 'Filename' },
    /* {
      name: 'filename',
      label: 'Filename',
      options: {
        customBodyRender: (value, tableMeta) => (
  
          <a
            href={`${apiUrlFile}/file/display?fileName=${value}`}  
            target="_blank"   
            rel="noopener noreferrer"   
            style={{ color: '#3f51b5', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {value}  
          </a>
        )
      }
    }, */
    { name: 'subtotal', label: 'Total (RM)' },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div>
            <Link
              component="button"
              variant="body2"
              //to={`/master/order/${orderData[tableMeta.rowIndex].orderId}`}
              onClick={() => handleEditClick(orderData[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '0.875rem', marginRight: '10px' }}
            >
            Detail
          </Link>
      
        </div>
          
        )
      }
    }
  ];

  // Options for the table
  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    pagination: true,
    rowsPerPage: 10,
    responsive: 'standard', // Or 'scrollMaxHeight'
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '12px', // Set default cell font size
          } 
        },
      } 
    },
  });

/*   const handleViewClick = (rowData) => {
    setSelectedOrderId(rowData.orderId);   
    setDialogOpen(true);   
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);   
    setSelectedOrderId(null);   
  }; */

  const handleEditClick = (rowData) => {
    setSelectedRow(rowData);
    setOpenEditForm(true); // Open EditForm
  };

  const handleEditFormClose = (shouldReload) => {
    setOpenEditForm(false);
    if (shouldReload) {
      fetchData();
    }
  };

    return ( 
      <div>
    <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}>  
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={fetchData} size="small">
        Fetch
        </Button>   
      </div>
       {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CircularProgress />
        </div>
      ) : ( 
        <ThemeProvider theme={theme}> 
          <MUIDataTable
            title={"Order List"}
            data={orderData}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
     
      )}
        </Box>
    
        {/* OrderDetail Dialog */}
        {/* <OrderDetail 
        orderId={selectedOrderId} 
        open={dialogOpen} 
        handleClose={handleCloseDialog} 
        />   */}

        {selectedRow && (
          <OrderDetail open={openEditForm} onClose={handleEditFormClose} initialValues={selectedRow} />
        )} 
    </div>
      
      );
};
 

export default Order;
