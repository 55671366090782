import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle,Typography,
   CircularProgress 
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const OrderForm = ({ open, onClose, initialValues }) => { 
    const [loading, setLoading] = useState(false); 
    //const [isOrderSuccess, setIsOrderSuccess] = useState(false); 
    const [errorMessage, setErrorMessage] = useState(''); // State to track error message
  
 
    const formik = useFormik({
        initialValues: {
            productVariant: '',
            quantity: '', 
        },
        validationSchema: Yup.object({
            productVariant: Yup.string().required('Variant is required'),
            quantity: Yup.number()
            .required('Quantity is required')
            .positive('Quantity must be positive')
            .integer('Quantity must be an integer')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                 const payload = {
                    ...values, 
                    Price: initialValues.price
                }; 
                const jsonData = JSON.stringify(payload);

                // Log the JSON string to the console
                console.log("Post data:", jsonData);
                
                await axios.put(`${apiUrl}/order/item/${initialValues.orderItemId}`, payload); 
                //setIsOrderSuccess(true);
                setErrorMessage(''); 

                onClose(true);
            } catch (error) {
                //setIsOrderSuccess(false); 
                setErrorMessage('Failed to update variant:', error);
                console.error("Error creating variant:", error);
            } finally {
                setLoading(false);
            } 
            
        }
    });

    useEffect(() => {
        //console.log(initialValues);
        if (initialValues) {
            formik.setFieldValue('quantity', initialValues.quantity);
            formik.setFieldValue('productVariant', initialValues.productVariant);
        } 

    }, [initialValues]); 

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{initialValues ? 'Update Variant' : 'New Variant'}</DialogTitle>
            <DialogContent>
              {/*   {isOrderSuccess && (
                <div className="success-message" style={{ color: 'green' }}>
                    Your variant has been successfully posted!
                </div>
                )} */}
                {errorMessage && (
                <div className="error-message" style={{ color: 'red' }}>
                    {errorMessage}
                </div>
                )} 

                <Typography variant="body1">
                Student: <strong>{initialValues.shipName}</strong> 
                </Typography>
                <Typography variant="body1">
                    P.Code: <strong>{initialValues.productCode}</strong> 
                </Typography>
                <TextField
                fullWidth
                margin="normal"
                label="Variant"
                name="productVariant" 
                value={formik.values.productVariant}
                onChange={formik.handleChange}
                error={formik.touched.productVariant && Boolean(formik.errors.productVariant)}
                helperText={formik.touched.productVariant && formik.errors.productVariant}
                />

                <TextField
                fullWidth
                margin="normal"
                label="quantity"
                name="quantity" 
                value={formik.values.quantity}
                onChange={formik.handleChange}
                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                helperText={formik.touched.quantity && formik.errors.quantity}
                />
                 
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose(false);
                        formik.resetForm();  
                    }}
                    color="secondary"
                >
                Cancel
                </Button>
                <Button type="submit" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    );
}

export default OrderForm;