import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import {  Button, Box, CircularProgress, Link } from '@mui/material'; 
import axios from 'axios'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OrderForm from './OrderForm';

const OrderReport = ( ) => { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);  
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row
  const [openEditForm, setOpenEditForm] = useState(false); // Control EditForm open state
 
 
  const fetchData = async () => {
    setLoading(true);
    try { 
      const apiUrl = process.env.REACT_APP_API_URL;
 
      
      const response = await axios.get(`${apiUrl}/order/csv`); 
      
      setData(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    } finally {
      setLoading(false);
    }
     
  };

  useEffect(() => {
    fetchData();
  }, []);

// Flatten order and order items
const flattenedData =
  data.length > 0
    ? data.map(order => {
        return order.orderItems.map(item => {
          return {
            orderId: order.orderId,
            orderCode: order.orderCode,
            paymentStatus: order.paymentStatus,
            fulfillmentStatus: order.fulfillmentStatus,
            createdAt: order.createdAt,
            shipName: order.shipName,
            shipPhone: order.shipPhone,
            shipAddress: order.shipAddress,
            shipCity: order.shipCity,
            shipPostcode: order.shipPostcode,
            shipState: order.shipState,
            shipCountry: order.shipCountry,
            orderItemId: item.orderItemId,
            productVariant: item.productVariant,
            quantity: item.quantity,
            subtotal: item.subtotal,
            productCode: item.product.product_Code,
            productTitle: item.product.title,
            price: item.product.price,
          };
        });
      }).flat()
    : [];
  //  console.log(flattenedData);
  // Column settings for MUIDataTable
  const columns = [
    { name: 'orderCode', label: 'Order Code' },
    { name: 'createdAt', label: 'Order Date' },
    { name: 'paymentStatus', label: 'Payment Status' },
    { name: 'fulfillmentStatus', label: 'Fulfillment Status' },
    { name: 'shipName', label: 'Student' },
    { name: 'shipPhone', label: 'Contact' },
    { name: 'shipAddress', label: 'School' },
    { name: 'productCode', label: 'P.Code' },
    { name: 'productTitle', label: 'P.Title' },
    { name: 'price', label: 'U.Price', options: { display: false } },
    { name: 'orderItemId', label: 'orderItemId' , options: { display: false }},
    { name: 'productVariant', label: 'Size' },
    { name: 'quantity', label: 'Qty' },
    { name: 'subtotal', label: 'Total (RM)' },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div>
            <Link
              component="button"
              variant="body2"
              onClick={() => handleEditClick(flattenedData[tableMeta.rowIndex])}
              underline="none"
              style={{ color: '#3f51b5', cursor: 'pointer', fontSize: '0.875rem', marginRight: '10px' }}
            >
            Edit
            </Link>
             
        </div>
          
        )
      }
    }
    
  ];

  // Options for the table
  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    pagination: true,
    rowsPerPage: 10,
    responsive: 'standard', // Or 'scrollMaxHeight'
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '12px', // Set default cell font size
          } 
        },
      } 
    },
  });

  const handleEditClick = (rowData) => {
    
    setSelectedRow(rowData);
    
    setOpenEditForm(true); // Open EditForm
  };

  const handleEditFormClose = (shouldReload) => {
    setOpenEditForm(false);
    if (shouldReload) {
      fetchData();
    }
  };
 

    return ( 
      <div>
    <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}>  
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button variant="contained" color="primary" onClick={fetchData} size="small">
        Fetch
        </Button>   
      </div>
       {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <CircularProgress />
        </div>
      ) : ( 
        <ThemeProvider theme={theme}> 
          <MUIDataTable
            title={"Order CSV"}
            data={flattenedData}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
     
      )}
        </Box>
        {selectedRow && (
          <OrderForm open={openEditForm} onClose={handleEditFormClose} initialValues={selectedRow} />
        )} 
    </div>
      
      );
};
 

export default OrderReport;
