import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HomeIcon  from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocalMallIcon from '@mui/icons-material/LocalMall'; 
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './Home';
import Customer from './Customer';
import Order from './Order';
import ProductTab from '../widgets/ProductTab';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Store from './Store';
import PaymentMethod from './PaymentMethod';
import User from './User';
import PaymentTab from '../widgets/PaymentTab';
import OrderReport from './OrderReport';

const drawerWidth = 240;

function Master(props) {
  const { window, _onLogout  } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [desktopOpen, setDesktopOpen] = React.useState(true); // State to control desktop drawer
  const [submenuOpen, setSubmenuOpen] = React.useState(false); // State for submenu
  const [submenuOpen2, setSubmenuOpen2] = React.useState(false); // State for submenu
  const [anchorEl, setAnchorEl] = React.useState(null); // State for profile menu
  const location = useLocation(); // Get the current location

  // Function to toggle mobile drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Function to toggle desktop drawer
  const handleDesktopDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };

  // Function to toggle submenu
  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleSubmenuToggle2 = () => {
    setSubmenuOpen2(!submenuOpen2);
  };

  // Function to open profile menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close profile menu
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to check if the path is selected
  const isSelected = (path) => location.pathname === path;

  // Expand submenu if the current path matches one of its items
  React.useEffect(() => {
    const paths = [
      '/master/store' ,
      '/master/product' ,
      '/master/payment' ,
      '/master/user' ,
    ];
    if (paths.some(path => location.pathname.startsWith(path))) {
      setSubmenuOpen(true);
      //setSubmenuOpen2(false);
    }
    /* const paths2 = [
      '/dashboard/GoalTable' ,
      '/dashboard/AssistTable' ,
      '/dashboard/SaveTable' 
    ];
    if (paths2.some(path => location.pathname.startsWith(path))) {
      setSubmenuOpen2(true);
      setSubmenuOpen(false);
    } */
    localStorage.setItem('lastPath', location.pathname);
  }, [location.pathname]);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem disablePadding component={Link} to="/master/home">
          <ListItemButton sx={{ backgroundColor: isSelected('/master/home') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        
      </List>
  
      <List>
        <ListItem disablePadding component={Link} to="/master/customer">
            <ListItemButton sx={{ backgroundColor: isSelected('/master/customer') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' }}>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Customer" />
            </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding component={Link} to="/master/order">
            <ListItemButton sx={{ backgroundColor: isSelected('/master/order') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' }}>
            <ListItemIcon>
                <Inventory2Icon />
            </ListItemIcon>
            <ListItemText primary="Order" />
            </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding component={Link} to="/master/order_report">
            <ListItemButton sx={{ backgroundColor: isSelected('/master/order_report') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' }}>
            <ListItemIcon>
                <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
            </ListItemButton>
        </ListItem>
      </List>

      {/* <List>
        <ListItem disablePadding component={Link} to="/master/product">
            <ListItemButton sx={{ backgroundColor: isSelected('/master/product') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' }}>
            <ListItemIcon>
                <LocalMallIcon />
            </ListItemIcon>
            <ListItemText primary="Product" />
            </ListItemButton>
        </ListItem>
      </List> */}
   
      <List>
        <ListItem disablePadding onClick={handleSubmenuToggle}>
            <ListItemButton>
              <ListItemIcon>
             
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Setup" />
              {submenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding component={Link} to="/master/store">
                <ListItemButton sx={{ 
                  pl: 4, 
                  backgroundColor: isSelected('/master/store') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' 
                }}>
                  {/* <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon> */}
                  <ListItemText primary="Store" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding component={Link} to="/master/product">
                <ListItemButton sx={{ 
                  pl: 4, 
                  backgroundColor: isSelected('/master/product') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' 
                }}> 
                  <ListItemText primary="Product" />
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding component={Link} to="/master/productcategory">
                <ListItemButton sx={{ 
                  pl: 4, 
                  backgroundColor: isSelected('/master/productcategory') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' 
                }}> 
                  <ListItemText primary="Product Category" />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding component={Link} to="/master/payment">
                <ListItemButton sx={{ 
                  pl: 4, 
                  backgroundColor: isSelected('/master/payment') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' 
                }}> 
                  <ListItemText primary="Payment Method" />
                </ListItemButton>
              </ListItem> 
              <ListItem disablePadding component={Link} to="/master/user">
                <ListItemButton sx={{ 
                  pl: 4, 
                  backgroundColor: isSelected('/master/user') ? 'rgba(0, 0, 0, 0.08)' : 'inherit' 
                }}> 
                  <ListItemText primary="Users" />
                </ListItemButton>
              </ListItem> 
            </List>
          </Collapse>
        </List>
       
       
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove('token', { path: '/' });
    console.log('Logged out!');
    _onLogout();  // Update authentication state in App.js
    navigate('/');  // This should now work since App.js will re-render
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${desktopOpen ? drawerWidth : 0}px)` },
          ml: { sm: `${desktopOpen ? drawerWidth : 0}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="close desktop drawer"
            edge="start"
            onClick={handleDesktopDrawerToggle}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Store Manager
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="profile"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: desktopOpen ? drawerWidth : 0 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          open={desktopOpen}
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: desktopOpen ? drawerWidth : 0,
              transition: 'width 0.3s',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${desktopOpen ? drawerWidth : 0}px)` } 
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="customer" element={<Customer />} /> 
          <Route path="order" element={<Order />} /> 
          <Route path="order_report" element={<OrderReport />} /> 
          <Route path="store" element={<Store />} /> 
          <Route path="product" element={<ProductTab />} /> 
          <Route path="payment" element={<PaymentTab />} /> 
          <Route path="user" element={<User />} /> 
          {/* <Route path="send-email" element={<div>Send Email Page</div>} /> 
          <Route path="GroupTable" element={<GroupTable />} /> 
          <Route path="TeamTable" element={<TeamTable />} /> 
          <Route path="PlayerTable" element={<PlayerTable />} /> 
          <Route path="GoalTable" element={<GoalTable />} /> 
          <Route path="AssistTable" element={<AssistTable />} /> 
          <Route path="SaveTable" element={<SaveTable />} /> 
          <Route path="MatchTable" element={<MatchTable />} /> */}
        </Routes>  
      </Box>
    </Box>
  );
}

Master.propTypes = {
  window: PropTypes.func,
};

export default Master;
