import React, { useState, useEffect } from 'react';
import {
    Checkbox, FormControlLabel, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  MenuItem, CircularProgress 
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
 

const PaymentForm = ({ open, onClose, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataBank, setDataBank] = useState([]);
  const [dataBankLoading, setDataBankLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(''); // State to track error message


  const formik = useFormik({
    initialValues: {
      paymentTypeId: '',
      paymentBankId: '',
      accountName: '',
      accountNo: '',
      status: 'Disabled',
    },
    validationSchema: Yup.object({
        paymentTypeId: Yup.string().required('Payment Type is required'),
        paymentBankId: Yup.string().required('Bank is required'), 
        accountName: Yup.string().required('Account Name is required'), 
        accountNo: Yup.string().required('Account No is required'), 
        
    }),
    onSubmit: async (values) => {
       setLoading(true);
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        // Convert the form data object to a JSON string
        const jsonData = JSON.stringify(values);

        // Log the JSON string to the console
        console.log("Post data:", jsonData);
        //console.log(initialValues.id);
        if (initialValues) {
          await axios.put(`${apiUrl}/paymentoption/${initialValues.paymentOptionId}`, values);
        } else {
          await axios.post(`${apiUrl}/paymentoption`, values); 
        }
        setErrorMessage(''); // Clear any previous errors
        onClose(true);
      } catch (error) {
        setErrorMessage('Failed to create data:', error);  
        console.error("Error creating payment method:", error);
      } finally {
        setLoading(false);
      } 
      
    }
  });

 

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        setDataLoading(true);
        
        const response = await axios.get(`${apiUrl}/paymenttype`); 
        setData(response.data);
      } catch (error) {
        console.error("Error fetching payment type:", error);
      } finally {
        setDataLoading(false);
      }

      try {
        setDataBankLoading(true);
         
        const response = await axios.get(`${apiUrl}/paymentbank`); 
        setDataBank(response.data);
      } catch (error) {
        console.error("Error fetching payment bank:", error);
      } finally {
        setDataBankLoading(false);
      }
    };

    if (open) {
      fetchData();
      if (initialValues) {
        formik.setFieldValue('accountName', initialValues.accountName);
        formik.setFieldValue('accountNo', initialValues.accountNo);
        formik.setFieldValue('status', initialValues.status);
        
      }

    } else {
      formik.resetForm();
      setData([]); 
      setDataBank([]); 
    }
  }, [open]);

   useEffect(() => {
    //console.log(initialValues);
    if (initialValues && data.length > 0) {
      formik.setFieldValue('paymentTypeId', initialValues.paymentType.paymentTypeId);
    } 

    if (initialValues && dataBank.length > 0) {
      formik.setFieldValue('paymentBankId', initialValues.paymentBank.paymentBankId);
    } 
  }, [data, dataBank, initialValues]); 


  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <form onSubmit={formik.handleSubmit}>
      <DialogTitle>{initialValues ? 'Edit Payment Method' : 'New Payment Method'}</DialogTitle>
        <DialogContent>
          {errorMessage && (
            <div className="error-message" style={{ color: 'red' }}>
                {errorMessage}
            </div>
            )}
          <TextField
            fullWidth
            margin="normal"
            select
            label="Payment Type"
            name="paymentTypeId"
            value={formik.values.paymentTypeId}
            onChange={formik.handleChange}
            error={formik.touched.paymentTypeId && Boolean(formik.errors.paymentTypeId)}
            helperText={formik.touched.paymentTypeId && formik.errors.paymentTypeId}
            disabled={dataLoading} // Disable until teams are loaded
          >
            {dataLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
                data.map((item) => (
                <MenuItem key={item.paymentTypeId} value={item.paymentTypeId}>
                  {item.title}
                </MenuItem>
              ))
            )}
          </TextField>

          <TextField
            fullWidth
            margin="normal"
            select
            label="Bank"
            name="paymentBankId"
            value={formik.values.paymentBankId}
            onChange={formik.handleChange}
            error={formik.touched.paymentBankId && Boolean(formik.errors.paymentBankId)}
            helperText={formik.touched.paymentBankId && formik.errors.paymentBankId}
            disabled={dataBankLoading} // Disable until teams are loaded
          >
            {dataBankLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
                dataBank.map((item) => (
                <MenuItem key={item.paymentBankId} value={item.paymentBankId}>
                  {item.title}
                </MenuItem>
              ))
            )}
          </TextField>

          <TextField
            fullWidth
            margin="normal"
            label="Account Name"
            name="accountName" 
            value={formik.values.accountName}
            onChange={formik.handleChange}
            error={formik.touched.accountName && Boolean(formik.errors.accountName)}
            helperText={formik.touched.accountName && formik.errors.accountName}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Account No."
            name="accountNo" 
            value={formik.values.accountNo}
            onChange={formik.handleChange}
            error={formik.touched.accountNo && Boolean(formik.errors.accountNo)}
            helperText={formik.touched.accountNo && formik.errors.accountNo}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="status"
                color="primary"
                checked={formik.values.status === "Enabled"} // Set checked based on value being 1
                onChange={() => formik.setFieldValue("status", formik.values.status === "Enabled" ? "Disabled" : "Enabled")} // Toggle between 1 and 0
              />
            }
            label="Enabled"
          />
          {/* <TextField
            fullWidth
            margin="normal"
            select
            label="Team"
            name="team"
            value={formik.values.team}
            onChange={formik.handleChange}
            error={formik.touched.team && Boolean(formik.errors.team)}
            helperText={formik.touched.team && formik.errors.team}
          >
            <MenuItem value="Team A">Team A</MenuItem>
            <MenuItem value="Team B">Team B</MenuItem>
            <MenuItem value="Team C">Team C</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          /> */}

        </DialogContent>
        <DialogActions>
        <Button
            onClick={() => {
              onClose(false);
              formik.resetForm();
              setData([]);
              setDataBank([]); 
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading ||  dataLoading ||  dataBankLoading}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PaymentForm;
