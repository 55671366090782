import React, { useState } from 'react';
import { CssBaseline, Tabs, Tab, Box, Paper} from '@mui/material';
import Product from '../components/Product';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ProductTab() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    
 <>
 <CssBaseline />

      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="product tabs"
          variant="fullWidth"
            indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Product" />
          <Tab label="Category" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
       <Product />
        
      </TabPanel>
      <TabPanel value={value} index={1}>
       
        
      </TabPanel>
      </>

  );
}

export default ProductTab;
