import React, { useState, useEffect } from 'react';
import {
    Checkbox, FormControlLabel, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  MenuItem, CircularProgress
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ProductForm = ({ open, onClose, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(true);

  const formik = useFormik({
    initialValues: {
        product_Category_Id: '',
        product_Code: '',
        title: '',
        description: '',
        brand: '',
        status: 'INACTIVE',
        stock_Quantity: '',
        price: '',
    },
    validationSchema: Yup.object({
        product_Category_Id: Yup.string().required('Category is required'),
        product_Code: Yup.string().required('Code is required'),
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        brand: Yup.string().required('Brand is required'),
        status: Yup.string().required('Status is required'),
        stock_Quantity: Yup.number()
            .required('Stock is required')
            .positive('Stock must be positive')
            .integer('Stock must be an integer'),
        price: Yup.number()
            .required('Price is required')
            .positive('Price must be positive')
            .integer('Price must be an integer')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const payload = {
          ...values, 
          image: ""
      };  
        const jsonData = JSON.stringify(payload);

        // Log the JSON string to the console
        console.log("Post data:", jsonData);
        //console.log(initialValues.product_Id);
       if (initialValues) {
          await axios.put(`${apiUrl}/product/${initialValues.product_Id}`, payload);
        } else {
          await axios.post(`${apiUrl}/product`, payload); 
        }
        onClose(true); 
      } catch (error) {
        console.error("Error creating product:", error);
      } finally {
        setLoading(false);
      }
    }
  });

 

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoadingCategory(true);
        
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/productcategory`); 
        setCategory(response.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      } finally {
        setLoadingCategory(false);
      }
    };

    if (open) {
      fetchCategory();
      if (initialValues) {
        //console.log(initialValues);
        formik.setFieldValue('product_Code', initialValues.product_Code);
        formik.setFieldValue('title', initialValues.title);
        formik.setFieldValue('description', initialValues.description);
        formik.setFieldValue('brand', initialValues.brand);
        formik.setFieldValue('status', initialValues.status);
        formik.setFieldValue('stock_Quantity', initialValues.stock_Quantity);
        formik.setFieldValue('price', initialValues.price);
      }
    } else {
      formik.resetForm();
      setCategory([]); 
    }
  }, [open]);

  useEffect(() => {
    if (initialValues && category.length > 0) {
      formik.setFieldValue('product_Category_Id', initialValues.product_Category_Id);
    } 
  }, [category, initialValues]);

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <form onSubmit={formik.handleSubmit}>
      <DialogTitle>{initialValues ? 'Edit Product' : 'New Product'}</DialogTitle>
        <DialogContent>
         
          <TextField
            fullWidth
            margin="normal"
            select
            label="Category"
            name="product_Category_Id"
            value={formik.values.product_Category_Id}
            onChange={formik.handleChange}
            error={formik.touched.product_Category_Id && Boolean(formik.errors.product_Category_Id)}
            helperText={formik.touched.product_Category_Id && formik.errors.product_Category_Id}
            disabled={loadingCategory} // Disable until category are loaded
          >
            {loadingCategory ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              category.map((item) => (
                <MenuItem key={item.productCategoryId} value={item.productCategoryId}>
                  {item.title} 
                </MenuItem>
              ))
            )}
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            label="Product Code"
            name="product_Code"
            value={formik.values.product_Code}
            onChange={formik.handleChange}
            error={formik.touched.product_Code && Boolean(formik.errors.product_Code)}
            helperText={formik.touched.product_Code && formik.errors.product_Code}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Brand"
            name="brand"
            value={formik.values.brand}
            onChange={formik.handleChange}
            error={formik.touched.brand && Boolean(formik.errors.brand)}
            helperText={formik.touched.brand && formik.errors.brand}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="status"
                color="primary"
                checked={formik.values.status === "ACTIVE"} // Set checked based on value being 1
                onChange={() => formik.setFieldValue("status", formik.values.status === "ACTIVE" ? "INACTIVE" : "ACTIVE")} // Toggle between 1 and 0
              />
            }
            label="ACTIVE"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Stock Quantity"
            name="stock_Quantity"
            type="number"
            value={formik.values.stock_Quantity}
            onChange={formik.handleChange}
            error={formik.touched.stock_Quantity && Boolean(formik.errors.stock_Quantity)}
            helperText={formik.touched.stock_Quantity && formik.errors.stock_Quantity}
          /> 
          <TextField
            fullWidth
            margin="normal"
            label="Price"
            name="price"
            type="number"
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          /> 
        </DialogContent>
        <DialogActions>
        <Button
            onClick={() => {
              onClose(false);
              formik.resetForm();
              setCategory([]);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading ||  loadingCategory}>
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProductForm;
