// src/components/Home.js
import React from 'react';
import { Typography, Box } from '@mui/material';
import QuickStart from '../widgets/QuickStart';
import   Dashboard   from '../widgets/Dashboard';

const Home = () => {
  return ( 
     
        
    <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}>  
        <Typography variant="h6">Quick Start:</Typography>
        <QuickStart />
        <Typography variant="h6">Dashboard:</Typography>
        <Dashboard />
        </Box>
     
  );
};

export default Home;
