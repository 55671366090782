import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Master from './components/Master';  
import SignInSide from './SignInSide';
import Cookies from 'js-cookie';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
 
  useEffect(() => {
    const token = Cookies.get('token');
    setIsAuthenticated(!!token);
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    Cookies.remove('token', { path: '/' });
    setIsAuthenticated(false);
  };

  
  // Get the last path from localStorage or default to /master
  const lastPath = localStorage.getItem('lastPath') || '/master/home';
  console.log({lastPath});
  return (
    <Router>
     
      <Routes>
         <Route
          path="/"
          element={isAuthenticated ? <Navigate to={lastPath} /> : <SignInSide onLoginSuccess={handleLoginSuccess} />}
        /> 
        <Route
          path="/master/*"
          element={isAuthenticated ? <Master _onLogout={handleLogout} /> : <Navigate to="/" />}
        />
        {/* <Route
          path="/master/*"
          element={<Master _onLogout={handleLogout} /> }
        /> */}
      </Routes>
    </Router>
  );
};

export default App;
