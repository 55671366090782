import React from 'react';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import storeSetup from '../assets/store_setup.jpg'; // Replace with your image path

const steps = [
  {
    title: 'Setup Store',
    image: `${storeSetup}`, // Replace with actual image
    link: '/master/store',
  },
  {
    title: 'Add Product',
    image: `${storeSetup}`, // Replace with actual image
    link: '/master/product',
  },
  {
    title: 'Add Payment Method',
    image:  `${storeSetup}`, // Replace with actual image
    link: '/master/payment',
  },
];

const QuickStart = () => {
  return (
    
    <Box sx={{ marginTop: 2, marginBottom: 4 }}>
    <Grid container spacing={4}>
      {steps.map((step, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardActionArea component={Link} to={step.link}>
              <CardMedia
                component="img"
                alt={step.title}
                height="140"
                image={step.image}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {step.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
};

export default QuickStart;
