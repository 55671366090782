import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  MenuItem, CircularProgress, Box
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ProductVariant = ({ open, onClose, initialValues }) => {
    const [tLoading, setTypeLoading] = useState(false);
    const [dLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [typeData, setTypeData] = useState([]);
    const [data, setData] = useState([]);
    const [isOrderSuccess, setIsOrderSuccess] = useState(false); // State to track success
    const [errorMessage, setErrorMessage] = useState(''); // State to track error message

    const fetchData = async () => {
        console.log(initialValues.product_Id);
        setDataLoading(true);
        try { 
            const apiUrl = process.env.REACT_APP_API_URL;
            
            const response = await axios.get(`${apiUrl}/productvariant/${initialValues.product_Id}`);
            
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setDataLoading(false);
        }
        
    };

     useEffect(() => {
        const fetchTypeData = async () => {
          const apiUrl = process.env.REACT_APP_API_URL;
          try {
            setTypeLoading(true);
            
            const response = await axios.get(`${apiUrl}/productvariant/type`); 
            setTypeData(response.data);
          } catch (error) {
            console.error("Error fetching product variant type:", error);
          } finally {
            setTypeLoading(false);
          }
    
 
        };
    
        if (open) {
          fetchData();
          fetchTypeData();
 
        } else {
          formik.resetForm();
          setIsOrderSuccess(false);
          setTypeData([]);      
        }
      }, [open]);
 

   /* useEffect(() => {
        if (initialValues && open) {
            setIsOrderSuccess(false);
            formik.resetForm();
            fetchData();
        }
    }, [initialValues, open]); */
 
    const columns = [ 
        { name: 'productId', label: 'Product', options: { display: false } },
        { name: 'typeId', label: 'Type', options: { display: false } },
        { name: 'productTitle', label: 'Product' }, 
        { name: 'variantName', label: 'Type' },  
        { name: 'variantValue', label: 'Value' }, 
        
    ];
 
    // Options for the table
    const options = {
        selectableRows: 'none',
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        pagination: false,
        responsive: 'standard', // Or 'scrollMaxHeight'
    };

    const theme = createTheme({
        components: {
        MuiTableCell: {
            styleOverrides: {
            root: {
                fontSize: '12px',  
            } 
            },
        } 
        },
    }); 

    const formik = useFormik({
        initialValues: {
            variantType: '',
            variantValue: '', 
        },
        validationSchema: Yup.object({
            variantType: Yup.string().required('Variant Type is required'),
            variantValue: Yup.string().required('Value is required'),  
            
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const payload = {
                    ...values,
                    TypeId: values.variantType,
                    ProductId: initialValues.product_Id
                };
                const jsonData = JSON.stringify(payload);

                // Log the JSON string to the console
                console.log("Post data:", jsonData);
                
                await axios.post(`${apiUrl}/productvariant`, payload); 
                setIsOrderSuccess(true);
                setErrorMessage(''); 
                fetchData();
                //onClose(true);
            } catch (error) {
                setIsOrderSuccess(false); 
                setErrorMessage('Failed to update variant:', error);
                console.error("Error creating variant:", error);
            } finally {
                setLoading(false);
            } 
            
        }
    });

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{initialValues ? 'Add Variant' : 'New Variant'}</DialogTitle>
            <DialogContent>
                {isOrderSuccess && (
                <div className="success-message" style={{ color: 'green' }}>
                    Your variant has been successfully posted!
                </div>
                )}
                {errorMessage && (
                <div className="error-message" style={{ color: 'red' }}>
                    {errorMessage}
                </div>
                )}
                <TextField
                fullWidth
                margin="normal"
                select
                label="variantType"
                name="variantType"
                value={formik.values.variantType}
                onChange={formik.handleChange}
                error={formik.touched.variantType && Boolean(formik.errors.variantType)}
                helperText={formik.touched.variantType && formik.errors.variantType}
                >
                    {tLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
                typeData.map((team) => (
                <MenuItem key={team.productVariantTypeId} value={team.productVariantTypeId}>
                  {team.name}  
                </MenuItem>
              ))
            )} 
                 {/* <MenuItem value="1">Size</MenuItem>
                <MenuItem value="2">Color</MenuItem>
                <MenuItem value="3">Material</MenuItem>  */}
                </TextField>
            
                <TextField
                fullWidth
                margin="normal"
                label="Value"
                name="variantValue" 
                value={formik.values.variantValue}
                onChange={formik.handleChange}
                error={formik.touched.variantValue && Boolean(formik.errors.variantValue)}
                helperText={formik.touched.variantValue && formik.errors.variantValue}
                />  
            
                 <Box sx={{  bgcolor: 'background.paper', p: 2, pb: 10 }}>  
    
                    {dLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <CircularProgress />
                        </div>
                    ) : (
                
                        
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                            title={"Variants"}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                
                    )}
                </Box> 
            </DialogContent>
            <DialogActions>
            <Button
                onClick={() => {
                    onClose(false);
                    formik.resetForm();
                    setData([]);
                    setIsOrderSuccess(false);
                }}
                color="secondary"
                >
                Cancel
                </Button>
                <Button type="submit" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    );
}

export default ProductVariant;